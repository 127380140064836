:root {
  --bg: #23272c;
  --bg-alt: #e1e8ed;
  --text-dark: #202122;
  --text-light: #ffffff;
  --text-muted: #6b778c;
  --text-link: #0645ad;
  --dashes: #5c7080;
  --incorrect: #990000;
  --correct: #339966;
  --primary: #006699;
  --box-border-radius: 8px;
  --button-border-radius: 20px;
}

html, body {
  box-sizing: border-box;
  background-color: var(--bg);
  color: var(--text-dark);
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--text-link);
}

/* StartScreen */

.start-screen {
  margin-top: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.start-screen > h2 {
  color: var(--text-light);
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  margin: 0 5vw 40px;
}

.start-screen > button {
  border: none;
  background: var(--primary);
  color: var(--text-light);
  padding: 10px 24px;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
  white-space: nowrap;
  width: 140px;
}

.start-screen > button:hover {
  filter: brightness(1.3);
}

/* Board */

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* LifeBar */

.life-bar {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.heart {
  width: 50px;
  margin: 10px;
}

/* Score */

.score {
  margin: 10px 0 50px;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--text-light);
  transform: skewX(-12deg);
  border-radius: var(--box-border-radius);
  padding: 10px 24px;
  text-align: left;
}

.score > p {
  font-weight: 700;
  font-size: 24px;
}

/* Question */

.question {
  margin: 0 10% 0;
  color: var(--text-light);
  font-size: clamp(1.5rem, 2vw, 4rem);
  text-align: center;
}

/* AnswerList */

.answer-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-click {
  pointer-events: none;
}

/* Answer */

.answer {
  background-color: var(--bg-alt);
  border-radius: var(--box-border-radius);
  margin: 10px 0 0;
  padding: 5px 15px;
  width: clamp(25%, 500px, 80%);
  list-style: none;
  cursor: pointer;
  font-size: clamp(1.2rem, 1vw, 4rem);
  color: var(--text-dark);
}

/* Game Over */

.game-over {
  margin: 0 20vw 0;
  color: var(--text-light);
  font-size: 2rem;
  text-align: center;
}

.game-over > button {
  border: none;
  background: var(--primary);
  color: var(--text-light);
  padding: 10px 24px;
  border-radius: var(--button-border-radius);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
  white-space: nowrap;
  width: 140px;
}

.game-over > button:hover {
  filter: brightness(1.3);
}